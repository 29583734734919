// export const API_BASE_URL = 'http://localhost:3000';
export const API_BASE_URL = 'https://dish-api.jag.network';
export const API_HELIUM_URL = 'https://sharkfed.api.stakejoy.com';
export const API_HELIUM_NODE_JAG_URL = 'https://api.helium.jag.network';
export const HOTSPOTTY_TOKEN = 'AqmgYnbQ1YsK9Lj91GTA3wmv3C36GnLs';
export const HOTSPOTTY_BASE_URL = 'https://beta-api.hotspotty.net/api/v1';
export const FIRE_BASE = {
    apiKey: "AIzaSyBwlenEydo-2pjcWqSyHOpwFM4cHvYuXuA",
    authDomain: "dish-da1c6.firebaseapp.com",
    projectId: "dish-da1c6",
    storageBucket: "dish-da1c6.appspot.com",
    messagingSenderId: "565402615524",
    appId: "1:565402615524:web:f9e0bc987fba2502e5b6f1",
    measurementId: "G-0EKY8G28BQ"
}