<template>
  <v-app-bar
    app
    class="white px-md-5"
    elevation="0"
    :height="$vuetify.breakpoint.mdAndUp ? '100px' : '70px'"
  >
    <v-container>
      <div class="d-none d-md-block">
        <v-row align="center" style="height:70px;">
          <v-col cols="2">
            <div class="d-flex align-center">
              <v-img
                alt="Logo"
                class="shrink mr-2"
                contain
                src="@/assets/Dish-Logo.jpeg"
                transition="scale-transition"
                width="75"
              />
            </div>
          </v-col>
          <v-col cols="8">
            <div class="d-flex justify-content-center centerMenu">
              <router-link :to="{ name: 'billing' }" v-if="isAccount" class="menu-item"
                >Billing</router-link
              >
              <router-link :to="{ name: 'account' }" v-if="isAccount" class="menu-item"
                >Account</router-link
              >
              <router-link :to="{ name: 'hotspot' }" v-if="isAccount" class="menu-item"
                >Hotspot</router-link
              >
              <router-link :to="'/users'" v-if="isUserManagement" class="menu-item"
                >Users</router-link
              >
              <router-link :to="'/reports'" v-if="isReports" class="menu-item"
                >Reports</router-link
              >
              <router-link :to="'/commissions'" v-if="isRequest" class="menu-item"
                >Request</router-link
              >
              <router-link :to="'/emailtemplate'" v-if="isEmailTemplate" class="menu-item"
                >Email Template</router-link
              >
              <router-link :to="'/email'" v-if="isEmail" class="menu-item"
                >Support Request</router-link
              >
              <v-menu offset-y v-if="isAdminUser || isManageRoles || isSuperAdmin">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    href="javascript:void(0)"
                    class="menu-item"
                    :class="isSettingMenuSelected == true ? 'router-link-exact-active' : ''"
                    text
                    v-bind="attrs"
                    v-on="on">
                    Settings
                    <v-icon>mdi-chevron-down</v-icon>
                  </a>
                </template>
                <v-list>
                  <v-list-item v-if="isAdminUser" :to="'/adminusers'"  dense>
                    <v-list-item-title>Admins</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isManageRoles" :to="'/manageroles'" dense>
                    <v-list-item-title>Roles</v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="'/schedulers'" dense> 
                    <v-list-item-title>Schedulers</v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="'/emailsettings'" dense> 
                    <v-list-item-title>Email Settings</v-list-item-title>
                  </v-list-item>
                  <v-list-item  v-if="isSuperAdmin"  :to="'/blacklists'" dense> 
                    <v-list-item-title>Black List</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isSuperAdmin" :to="'/logs'" dense> 
                    <v-list-item-title>Logs</v-list-item-title>
                    </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          
          <v-col cols="2">
            <div>
              <v-btn
                color="blue darken-4"
                dark
                outlined
                rounded
                @click="signOut"
              >
                Logout
              </v-btn>
            </div>
          </v-col>
          
          <!--<div class="text-right" v-if="user.loggedIn">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue darken-4"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  rounded
                >
                  {{ user.data != null ? user.data.displayName : "" }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item dense @click.prevent="signOut">
                  <v-list-item-title class="subtitle-2"
                    >Sign Out</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>-->
        </v-row>
        <v-row align="center" style="height:20px; margin-bottom:2px;">
          <v-col>
            <div class="mr-2 subtitle-2 d-flex justify-end">
            HNT:
            <span class="gold_4--text darken-4 ml-2">
              $
              {{
                hntPrice
              }}
            </span>
             |
            Mobile:
            <span class="gold_4--text darken-4 ml-2">
              $
              {{
                hmtPrice    
              }}
            </span>&nbsp;
            |
            IOT:
            <span class="gold_4--text darken-4 ml-2">
              $
              {{
                iotPrice
              }}
            </span>
          </div>
          </v-col>
        </v-row>
      </div>

      <v-row align="center" class="d-md-none my-0 justify-space-between">
        <v-menu v-if="isLoggedin" left bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <template>
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
          </template>

          <v-list>
            <v-list-item :to="{ name: 'billing' }" dense>
              <v-list-item-title class="subtitle-2" >Billing</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'account' }" dense>
              <v-list-item-title class="subtitle-2" >Account</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'hotspot' }" v-if="isAccount" dense>
              <v-list-item-title class="subtitle-2" >Hotspot</v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/users'" v-if="isUserManagement" dense>
              <v-list-item-title class="subtitle-2" >Users</v-list-item-title>
            </v-list-item>
            
            <v-list-item :to="'/reports'" v-if="isReports" dense>
              <v-list-item-title class="subtitle-2" >Reports</v-list-item-title>
            </v-list-item>
            
            <v-list-item :to="'/commissions'" v-if="isRequest" dense>
              <v-list-item-title class="subtitle-2" >Request</v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/emailtemplate'" v-if="isEmailTemplate" dense>
              <v-list-item-title class="subtitle-2" >Email Template</v-list-item-title>
            </v-list-item>

            <v-list-item :to="'/email'" v-if="isEmail" dense>
              <v-list-item-title class="subtitle-2" >Support Request</v-list-item-title>
            </v-list-item>

            
                  <v-list-item v-if="isAdminUser" :to="'/adminusers'"  dense>
                    <v-list-item-title>Admins</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isManageRoles" :to="'/manageroles'" dense>
                    <v-list-item-title>Roles</v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="'/schedulers'" dense> 
                    <v-list-item-title>Schedulers</v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="'/emailsettings'" dense> 
                    <v-list-item-title>Email Settings</v-list-item-title>
                  </v-list-item>
                  <v-list-item  v-if="isSuperAdmin"  :to="'/blacklists'" dense> 
                    <v-list-item-title>Black List</v-list-item-title>
                  </v-list-item>
               
              
          </v-list>
        </v-menu>
        <div class="d-flex align-center">
          <router-link to="/account">
            <v-img
              alt="Logo"
              class="shrink mr-2"
              contain
              src="@/assets/Dish-Logo.jpeg"
              transition="scale-transition"
              width="65"
            />
          </router-link>
          
        </div>
        <div v-if="isLoggedin" class="text-right">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-4"
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                rounded
                small
                max-width="50px"
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  class="subtitle-2 gold_4--text darken-4 text-center"
                >
                  Hi, Admin
                  <div class="mr-2 subtitle-2 black--text">
                    HNT Conversion Price<span
                      class="purple--text darken-4 ml-2"
                      href="https://coinmarketcap.com/currencies/helium/"
                      >{{
                        oracleprice.data != null
                          ? (oracleprice.data.price / 100000000).toFixed(2)
                          : ""
                      }}
                      $
                    </span>
                  </div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense @click.prevent="signOut">
                <v-list-item-title class="subtitle-2"
                  >Sign Out</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-btn
          @click="signOut"
          color="blue darken-4"
          dark
          outlined
          rounded
          small
          v-else
        >
          <span class="mr-2">Logout</span>
        </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import { API_BASE_URL, API_HELIUM_URL, HOTSPOTTY_BASE_URL, HOTSPOTTY_TOKEN } from "../constants";

export default {
  name: "app-header",

  data: () => {
    return {
      oracleprice: {
        data: ""
      },
      isAccount:false,
      isLoggedin:false,
      isUserManagement :false,
      isRequest :false,
      isReports :false,
      isEmailTemplate :false,
      isEmail :false,
      isManageRoles :false,
      isAdminUser :false,
      permissions:[],
      isSettingMenuSelected: false,
      isSuperAdmin: 0,
      hmtPrice: '',
      iotPrice: '',
      hntPrice: ''
    };
  },
  watch:{
    $route(to, from){
      this.routClickHandler(to.name);
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
    currentRouteName() {
        return this.$route.name;
    }
  },
  mounted() {
    this.getPrice();
    this.getUserPermissions();
    this.routClickHandler(this.currentRouteName);
    this.checkSuperAdmin();
    var  id = JSON.parse(localStorage.getItem("user")).id;
      if(id){
        this.isLoggedin = true;
      }
    
  },
  methods: {
    getPrice(){
      axios
          .get(`${HOTSPOTTY_BASE_URL}/tokens/prices`, {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
            },
          })
          .then((resp) => {
            this.hmtPrice = resp.data.data.mobile.usd;
            this.iotPrice = resp.data.data.iot.usd;
            this.hntPrice = resp.data.data.hnt.usd
          }).catch((err) => {
            console.log(err);
          });
    },
    checkPermissions(){
      var exists = this.fields.some(function(field) {
        return field.value === this.field.value
      });
    },
    checkSuperAdmin(){
      let localUser = localStorage.getItem('user');
      localUser = JSON.parse(localUser);
      if(localUser.role_id == 1){
          this.isSuperAdmin = 1;
      }
    },
    getUserPermissions() {
      var  role_id = JSON.parse(localStorage.getItem("user")).role_id;
      if(!role_id){
        // alert("No Roles assigned yet, Please contact admin");
        return false;
      }          
      axios.get(`${API_BASE_URL}/user/permissions/${role_id}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        this.permissions = [];
        resp.data.forEach(element => {
          if(element.permission == "Account/Hotspot")
          {
            this.isAccount = true;
          }
          if(element.permission == "User Management")
          {
            this.isUserManagement = true;
          }
          if(element.permission == "Request")
          {
            this.isRequest = true;
          }
          if(element.permission == "Reports")
          {
            this.isReports = true;
          }
          if(element.permission == "Email Template")
          {
            this.isEmailTemplate = true;
          }
          if(element.permission == "Email")
          {
            this.isEmail = true;
          }
          if(element.permission == "Manage Roles")
          {
            this.isManageRoles = true;
          }
          if(element.permission == "Admin User")
          {
            this.isAdminUser = true;
          }
        });
      });
    },
    HNTopen() {
      window.open("https://coinmarketcap.com/currencies/helium/", "_blank");
    },
    signOut() {
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
    routClickHandler(name){
      if(name == "adminusers" || name == "manageroles" || name == "schedulers" || name == "emailsettings"){
        this.isSettingMenuSelected = true;
      }else{
        this.isSettingMenuSelected = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu-item {
  text-decoration: none !important;
  padding: 5px 10px;
  color: var(--v-secondary-base);
  font-size: 14px;

  &:hover {
    color: var(--v-primary-base);
  }

  &.router-link-exact-active {
    color: var(--v-gold_4-base);
  }

  &:not(:last-child) {
    border-right: 1px solid var(--v-primary-base);
  }

}
.v-btn:not(.v-btn--round).v-size--default {
    float: right;
}
.centerMenu {
      justify-content: center;
}
</style>
