<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Billing/Accurals
            </div>

            <v-dialog v-model="dialog" max-width="600px" height="1000px">
              <v-card>
                <v-card-title class="headline blue darken-4 white--text">
                  Update Variables
                   <v-spacer></v-spacer>
                 <v-btn icon @click="dialog = false" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-card-title>
                <v-card-text class="pa-3">
                  <div>
                    <v-text-field
                      v-model="jagFees"
                      color="#474DFF"
                      filled
                      clearable
                      label="JAG Fess" 
                      type="text"
                    ></v-text-field>
                  </div>
                </v-card-text>

                <v-card-text class="pa-3">
                  <div>
                    <v-text-field
                      v-model="calchipKit"
                      color="#474DFF"
                      filled
                      clearable
                      label="Calchip Kit Cost" 
                      type="text"
                    ></v-text-field>
                  </div>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn text dark color="success" @click="alert('hi')">
                    Save
                  </v-btn>
                  <v-btn text dark color="red" @click="alert('bye')">
                    Close
                  </v-btn>
                </v-card-actions>
             
              </v-card>
            </v-dialog>

            <v-dialog v-model="transactionsDialog" max-width="2000px" height="1000px">
              <v-card>
                <v-card-title class="headline blue darken-4 white--text">
                  Transactions
                   <v-spacer></v-spacer>
                 <v-btn icon @click="closeTransaction()" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-card-title>
                <v-col md="12" cols="12" class="my-2">
                  <v-card>
                    <div>
                      <vue-json-to-csv :json-data="this.transactions" :csvTitle="`billing_${currentDate}`" style="margin-left: 10px;">
                        <v-btn dark color="green">
                          Export<v-icon dark> mdi-microsoft-excel </v-icon>
                        </v-btn>
                      </vue-json-to-csv>
                    </div>
                    <v-card-title>
                      Hotspot: {{selectedHotspotName}}
                      <!-- <vue-json-to-csv
                          :json-data="this.allOwnedHotspots"  
                        >
                          <v-btn fab dark small color="green">
                            <v-icon dark> mdi-microsoft-excel </v-icon>
                          </v-btn>
                        </vue-json-to-csv> -->
                      <v-spacer></v-spacer>
                      Hotspot Address: {{selectedAddress}}
                    </v-card-title>
                    <div class="pa-2">
                  <v-data-table
                          :headers="transactionHeaders"
                          :items="transactions"
                          :key="name"
                          class="elevation-1"
                          :search="search"
                        >
                          <template v-slot:[`item.Hotspotname`]="{ item }">
                            <td>
                              {{ item.Hotspotname.replaceAll("-", " ") }}
                            </td>
                          </template>
                        </v-data-table>
                    </div>
                  </v-card>
                </v-col>
             
              </v-card>
            </v-dialog>

            <v-btn
              outlined
              dark
              small
              v-bind="attrs"
              v-on="on"
              @click="dialog = true;showSpinner()"
            >
              <v-icon> mdi-cog-outline </v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col md="3" v-for="month in monthWiseTotal" :key="month.month">
            <v-card class="my-2 border-left-1">
              <div>
                <div>
                  <!-- <v-card-title class="text-h6 green--text"> Daily </v-card-title> -->
                  <v-card-title class="text-h6"> Month - {{getMonthName(month.month)}} </v-card-title>
                  <!-- <v-card-subtitle
                    style="font-size: 0.8em"
                  >
                  {{month.month}}
                  </v-card-subtitle> -->
                  <v-card-text
                  >
                  <p style="margin-bottom: 0px;" class="text-h10">Total Mobile to JAG Daily RMS: <strong>{{ month.totalMonthlyMobileToJAGDailyRMS ? (month.totalMonthlyMobileToJAGDailyRMS).toFixed(2) : ''  }}</strong></p> 
                  <p style="margin-bottom: 0px;" class="text-h10">Total Mobile to CCC: <strong>{{ month.totalMonthlyMobileToCCC ? (month.totalMonthlyMobileToCCC).toFixed(2) : '' }}</strong></p>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </v-col>

        </v-row>
      </v-col>

      <v-col md="12" cols="12" class="my-2">
        <v-card>
          <v-card-title>
            <span>
            Tagged Hotspots
            </span>
            <vue-json-to-csv :json-data="this.allOwnedHotspots" :csvTitle="`billing_${currentDate}`" style="margin-left: 10px;">
              <v-btn dark color="green">
                Export<v-icon dark> mdi-microsoft-excel </v-icon>
              </v-btn>
            </vue-json-to-csv>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
         <v-data-table
                :headers="headers"
                :items="allOwnedHotspots"
                :key="name"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:[`item.Hotspotname`]="{ item }">
                  <td>
                    {{ item.Hotspotname.replaceAll("-", " ") }}
                  </td>
                </template>
                <template v-slot:[`item.hotspotStatus`]="{ item }">
                  <td class="capitalize">
                    {{ item.hotspotStatus }}
                  </td>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn small color="success" @click="openTransactions(item)"
                    >View transactions
                  </v-btn>
                </template>
              </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import {API_BASE_URL, API_HELIUM_URL, API_HELIUM_NODE_JAG_URL, HOTSPOTTY_BASE_URL, HOTSPOTTY_TOKEN } from '../constants';
import moment from "moment";

export default {
  setup() {},
  data() {
    return {
      currentDate: moment().format('YYYY-MM-DD'),
      calchipKit: 695,
      jagFees: 19.99,
      months: [],
      transactionsDialog: false,
      transactions: [],
      dialog: false,
      search: "",
      ownedHotspots: 0,
      onlineHotspots:0,
      offlineHotspots:0,
      finalexport: [],
      message: "",
      token: JSON.parse(localStorage.getItem("user")),
      accountAdded: [],
      snackbar: false,
      text: "Save Successful",
      timeout: 4000,
      attrs: '',
      on: '',
      uid: '',
      name: '',
      selectedAddress: '',
      selectedHotspotName: '',
      info:{
       "commission":20 /*added for temporary purpose*/
      },
       allOwnedHotspots: [],
      users: [],
      monthWiseTotal: []
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
      headers() {
      return [
      { text: "Actions", value: "action", sortable: false, align: "center" },

        {
          text: "Hotspot Name",
          align: "start",
          value: "hotspotName",
        },
        {
          text: "Mobile",
          value: "isMobile",
        },
        { text: "Hotspot Address", value: "hotspotAddress" },
        { text: "Total Mobile to JAG Daily RMS", value: "totalMobileToJAGDailyRMS" },
        { text: "Total Mobile to CCC", value: "totalMobileToCCC" },
        {
          text: "Status",
          value: "hotspotStatus",
        },
        { text: "Location", value: "hotspotLocation" },
        { text: "Lat Long", value: "latlng" },
        { text: "Owner", value: "hotspotOwnerEmail" },
        // { text: "Return", value: "hotspotReturn" },
      ];
    },
    transactionHeaders() {
      return [
        { text: "Date", value: "date" },
        { text: "Hotspot ID", value: "address", align: ' d-none'  },
        { text: "HotspotName", value: "hotspotName", align: ' d-none'  },
        { text: "Mobile Rewards Total", value: "mobileRewardsTotal" },
        { text: "Mobile Price as per CoinGecko", value: "coinGeckoPrice" },
        { text: "USD Made", value: "usd" },
        { text: "Amount Allocated to JAG RMS", value: "amountAllocatedJAG" },
        { text: "RMS Running Monthly Paydown", value: "rmsRunningMonthlyPaydown" },
        { text: "Mobile to JAG Daily for RMS", value: "mobileJAGDailyRMS" },
        { text: "95% of Remaining USD Balance for CCC SK Free", value: "remainingUSD" },
        { text: "Running Monthly SK Paydown", value: "runningMonthlySK" },
        { text: "Mobile to CCC", value: "mobileCCC" },
      ];
    },
  },

  beforeMount() {
     var ld = this.$root;
     ld.isLoading =   true;

      setTimeout(function(){
        ld.isLoading = false;
      },10000);
   
    this.getUserHotspots();

    // To get all account address
    this.getAddress();


    // this.$root.isLoading = false;
    //this.getHotspotName();
    //this.getuid();
  },
  methods: {
  getMonthName(monthNumber){
    return moment(monthNumber, 'M').format('MMMM');
  },
    coinGeckoPrices(){
      return new Promise (function( resolve, reject ) {
      axios.get(`https://api.coingecko.com/api/v3/coins/helium-mobile/market_chart?vs_currency=usd&days=365`, {
          headers: {
            accept: "application/json",
          }
        }).
        then((res) => {
          res = res.data.prices;
          let newArr = [];
          console.log('res', res);
          for(let i = 0; i < res.length; i++){
            newArr = [...newArr, {
              date: moment(res[i][0]).format('YYYY-MM-DD'),
              price: res[i][1],
              unix: res[i][0]
            }]
          }
          resolve(newArr);
        })
        .catch(() => {
          resolve([]);
        })
      });
    },
    closeTransaction(){
      this.transactionsDialog = false;
      this.transactions = [];
    },
    async openTransactions(hotspot){
      console.log('hotspot***', hotspot);
      this.loading = true;

      this.selectedAddress = hotspot.hotspotAddress,
      this.selectedHotspotName = hotspot.hotspotName,
      this.transactionsDialog = true;

      let startDate = moment('2024-06-01');
      let endDate = moment().format('YYYY-MM-DD');

      console.log('endDate', endDate);
      let data = this.getDates(startDate, endDate);

      //get coingeko prices
      let coinGecko = await this.coinGeckoPrices();

      for(let i = 0; i < data.length; i++){
        let dateExists = coinGecko.findIndex((x) => x.date == data[i].date);

        if(dateExists > -1){
          data[i].coinGeckoPrice = coinGecko[dateExists].price;
        }
      }


      console.log(coinGecko);

      let rewards = await this.getHMTRewardHotSpotty(hotspot.hotspotAddress);
      rewards  = rewards.mobile;
      console.log('rewards***', rewards);


      let monthlyData = [];
      for(let i = 0; i < data.length; i++){
        let dateExists = rewards.findIndex((x) => x.date == data[i].date);

        if(dateExists > -1){
          data[i].mobileRewardsTotal = rewards[dateExists].total;

          if(data[i].mobileRewardsTotal && data[i].coinGeckoPrice){
            data[i].usd = (data[i].mobileRewardsTotal * data[i].coinGeckoPrice).toFixed(2);
          }
        }

        let splitDate = data[i].date.split("-");

        //Check if months exists
        let monthExists = monthlyData.findIndex((x) => x.month == splitDate[1]);
        if(monthExists < 0){
          monthlyData = [...monthlyData, {
            month: splitDate[1],
            rmsMonthlySum: 0
          }];
        }
      }



      console.log('monthlyData**', monthlyData);

      let fees = this.jagFees;
      let calchipKit = this.calchipKit;
    
      //calculate amount allocated to JAG RMS
      for(let i = 0; i < data.length; i++){
        //update Monthly
        let splitDate = data[i].date.split("-");
        let monthlyIndex = monthlyData.findIndex((x) => x.month == splitDate[1]);
        console.log('monthlyIndex', monthlyIndex);

          if(i == 0){

            if((fees - data[i].usd) >  0){
              data[i].amountAllocatedJAG = data[i].usd;
              data[i].rmsRunningMonthlyPaydown = fees - data[i].usd;

              data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
              data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;

            }else{

              data[i].amountAllocatedJAG = fees;
              data[i].rmsRunningMonthlyPaydown = data[i].usd - fees;

              data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
              data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;

            }
            data[i].runningMonthlySK = calchipKit;

            monthlyData[monthlyIndex].rmsMonthlySum += data[i].amountAllocatedJAG ? parseFloat(data[i].amountAllocatedJAG) : 0;
          }else{
            let needed = fees - monthlyData[monthlyIndex].rmsMonthlySum;

            if(needed > 0){

              if((needed - data[i].usd) >  0){
              data[i].amountAllocatedJAG = data[i].usd;
              data[i].rmsRunningMonthlyPaydown = needed - data[i].usd;

              data[i].runningMonthlySK = calchipKit;
              data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
              data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;

              }else{

                data[i].amountAllocatedJAG = needed;
                data[i].rmsRunningMonthlyPaydown = '';

                data[i].remainingUSD = (data[i].usd  - needed) * 0.95;
                data[i].runningMonthlySK = calchipKit - data[i].remainingUSD;
                data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
                data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;


                calchipKit = calchipKit - data[i].remainingUSD;
              }

            }else{
                data[i].remainingUSD = data[i].usd * 0.95;
                data[i].runningMonthlySK = calchipKit - data[i].remainingUSD;
                data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
                data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;


                calchipKit = calchipKit - data[i].remainingUSD;
            }
            
            monthlyData[monthlyIndex].rmsMonthlySum += data[i].amountAllocatedJAG ? parseFloat(data[i].amountAllocatedJAG) : 0;

            

          }
      }
      
      console.log('monthlyData**', monthlyData);
      
      this.loading = true;
      this.transactions = data;

    },
    getDates(startDate, endDate){
      console.log('startDate', startDate);
      console.log('endDate', endDate);

      var now = startDate, dates = [];
      
      while (now.isSameOrBefore(endDate)) {
            dates.push(
              {
                date: now.format('YYYY-MM-DD'),
                address: this.selectedAddress,
                hotspotName: this.selectedHotspotName,
                mobileRewardsTotal: '',
                coinGeckoPrice: '',
                usd: '',
                amountAllocatedJAG: '',
                rmsRunningMonthlyPaydown: '',
                mobileJAGDailyRMS: '',
                remainingUSD: '',
                runningMonthlySK: '', 
                mobileCCC: ''
              }
              );
            now.add(1, 'days');
        }
      return dates;
    },
    async getHMTRewardHotSpotty(hotspotAddress){

      let minDate = '2024-06-01 00:00:00';
      minDate= moment(minDate).unix();
      let maxDate = moment().unix();

      return new Promise (function( resolve ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}/rewards?start=${minDate}&end=${maxDate}`, {
          headers: {
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          // console.log('res', res.data.data.rewards);

          let mobile = res.data.data.rewards.mobile;

          let newArr = []
          for(let i = 0; i < mobile.length; i++){
            newArr = [...newArr, {
              date: moment.unix(mobile[i].start_date).format('YYYY-MM-DD'),
              total: mobile[i].total
            }]
          }
          resolve({
            mobile: newArr
          });
        })
        .catch(() => {
          resolve({
            mobile: []
          });
        })
      })
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().replace('-',' ').indexOf(search) !== -1
      );
    },
    formatHotspotName(hotspotName) {
      let hotspot_name = hotspotName.replaceAll('-',' ');
      let hotspot_name_caps = hotspot_name.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      return hotspot_name_caps;
    },
    getAddress() {
      axios
        .get(`${API_BASE_URL}/account`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.accountAdded = resp.data;

          // To get all owned hotspot
          this.getAllOwnedHotspots();
        });
    },

    getUserHotspots(){
      axios
        .get(`${API_BASE_URL}/users/hotspot`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.users = resp.data;
        });
    },
    getRewards(array){

      let hotspots = [];
      let minDate = '2024-06-01 00:00:00';
      minDate= moment(minDate).unix();
      let maxDate = moment().unix();

      for(let i = 0; i < array.length; i++){
        hotspots = [...hotspots, array[i].id];
      }
      return new Promise (function( resolve ) {
        axios.post(`${HOTSPOTTY_BASE_URL}/hotspot/rewards-period`,{
          hotspot_ids: hotspots,
          start : minDate,
          end: maxDate
        }, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          res = res.data;
          let newArr = []
          for(let i = 0; i < res.length; i++){
            
            let rewards = [];
            for(let j = 0; j < res[i].rewards.mobile.length; j++){
              rewards = [...rewards, {
                date: moment.unix(res[i].rewards.mobile[j].start_date).format('YYYY-MM-DD'),
                total: res[i].rewards.mobile[j].total
              }]
            }
            
            newArr = [...newArr, {
              hotspot: res[i].hotspot_id,
              rewards
            }]
          }
          resolve(newArr);
        })
        .catch(() => {
          resolve([]);
        })
      })
    },
    async getAllOwnedHotspots() {
      let res = await this.getHotspottyWalletHotspotsDetail();
      let coinGeckoPrices = await this.coinGeckoPrices();
      let hotspotRewards = await this.getRewards(res.data);
      console.log('all hotspots rewards', hotspotRewards);

      let hotspots = res.data;
      this.ownedHotspots = res.total_hotspots;

      //get months
      let startDate = moment('2024-06-01');
      let endDate = moment().format('YYYY-MM-DD');
      let data = this.getDates(startDate, endDate);
      let monthlyData = [];
      for(let i = 0; i < data.length; i++){

        let splitDate = data[i].date.split("-");

        //Check if months exists
        let monthExists = monthlyData.findIndex((x) => x.month == splitDate[1]);
        if(monthExists < 0){
          monthlyData = [...monthlyData, {
            month: splitDate[1],
            totalMonthlyMobileToJAGDailyRMS: 0,
            totalMonthlyMobileToCCC: 0
          }];
        }
      }

      console.log('monthlyData**', monthlyData);


      for(let i = 0; i < hotspots.length; i++){
        let hotspot = hotspots[i];

        if(hotspot){

          if(hotspot.active){
            this.onlineHotspots++;
          }

          if(!hotspot.active){
            this.offlineHotspots++;
          }


          //get owner's email
          let owner = this.users.findIndex((x) => x.Haddress == hotspots[i].id && x.role == 0);
          let ownerEmail = 'N/A';
          if(owner > -1){
            ownerEmail = this.users[owner].email;
          }

          let hotspotObj = {};
          hotspotObj.hotspotName = this.formatHotspotName(hotspot.name);
          hotspotObj.hotspotAddress = hotspot.id;
          //get rmsRunningMonthlyPaydown and runningMonthlySK
          let rewardIndex = hotspotRewards.findIndex((x) => x.hotspot == hotspot.id);
          if(rewardIndex > -1){
            let res = await this.getTransaction(hotspotRewards[rewardIndex].rewards, coinGeckoPrices);
            console.log('res**', res);
            console.log('hotspot.id**', hotspot.id);
            
            for(let j = 0; j < res.monthlyData.length; j++){
              let monthIndex = monthlyData.findIndex((x) => x.month == res.monthlyData[j].month);

              if(monthIndex > -1){
                monthlyData[monthIndex].totalMonthlyMobileToJAGDailyRMS += res.monthlyData[j].totalMonthlyMobileToJAGDailyRMS;
                monthlyData[monthIndex].totalMonthlyMobileToCCC += res.monthlyData[j].totalMonthlyMobileToCCC;
              }
            }


            hotspotObj.totalMobileToJAGDailyRMS = res.totalMobileToJAGDailyRMS
            hotspotObj.totalMobileToCCC = res.totalMobileToCCC
          }
          hotspotObj.hotspotStatus = hotspot.active ? 'Online' : 'Offline';
          hotspotObj.hotspotLocation = hotspot.place;
          hotspotObj.isMobile = hotspot.radios.length > 0  ? 'Yes' : 'No';

          // hotspotObj.place = hotspot.place;
          hotspotObj.latlng = `${hotspot.lat} ${hotspot.lng}`
          // hotspotObj.hotspotOwnerEmail =  ownerEmail;
          hotspotObj.hotspotOwnerEmail = 'Dish';
          // hotspotObj.hotspotOwnerId = hotspot.helium_owner;
          // hotspotObj.hotspotReturn = '';



          this.allOwnedHotspots.push(hotspotObj); 
        }
      }

      this.monthWiseTotal = monthlyData;
      this.loading = false;
    },
    async getTransaction (rewards, coinGecko){
      // console.log('rewards', rewards);
      // console.log('coinGeckoPrices', coinGecko);

      let startDate = moment('2024-06-01');
      let endDate = moment().format('YYYY-MM-DD');

      let data = this.getDates(startDate, endDate);

      for(let i = 0; i < data.length; i++){
        let dateExists = coinGecko.findIndex((x) => x.date == data[i].date);

        if(dateExists > -1){
          data[i].coinGeckoPrice = coinGecko[dateExists].price;
        }
      }

      let monthlyData = [];
      for(let i = 0; i < data.length; i++){
        let dateExists = rewards.findIndex((x) => x.date == data[i].date);

        if(dateExists > -1){
          data[i].mobileRewardsTotal = rewards[dateExists].total;

          if(data[i].mobileRewardsTotal && data[i].coinGeckoPrice){
            data[i].usd = (data[i].mobileRewardsTotal * data[i].coinGeckoPrice).toFixed(2);
          }
        }

        let splitDate = data[i].date.split("-");

        //Check if months exists
        let monthExists = monthlyData.findIndex((x) => x.month == splitDate[1]);
        if(monthExists < 0){
          monthlyData = [...monthlyData, {
            month: splitDate[1],
            rmsMonthlySum: 0,
            totalMonthlyMobileToJAGDailyRMS: 0,
            totalMonthlyMobileToCCC: 0
          }];
        }
      }




      let fees = this.jagFees;
      let calchipKit = this.calchipKit;
    
      let totalMobileToJAGDailyRMS = 0;
      let totalMobileToCCC = 0;
      //calculate amount allocated to JAG RMS
      for(let i = 0; i < data.length; i++){
        //update Monthly
        let splitDate = data[i].date.split("-");
        let monthlyIndex = monthlyData.findIndex((x) => x.month == splitDate[1]);

          if(i == 0){

            if((fees - data[i].usd) >  0){
              data[i].amountAllocatedJAG = data[i].usd;
              data[i].rmsRunningMonthlyPaydown = fees - data[i].usd;

              data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
              data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;

            }else{

              data[i].amountAllocatedJAG = fees;
              data[i].rmsRunningMonthlyPaydown = data[i].usd - fees;

              data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
              data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;

            }
            data[i].runningMonthlySK = calchipKit;

            monthlyData[monthlyIndex].rmsMonthlySum += data[i].amountAllocatedJAG ? parseFloat(data[i].amountAllocatedJAG) : 0;

             //month wise for tiles
            monthlyData[monthlyIndex].totalMonthlyMobileToJAGDailyRMS +=  data[i].mobileJAGDailyRMS ? data[i].mobileJAGDailyRMS : 0;
            monthlyData[monthlyIndex].totalMonthlyMobileToCCC +=  data[i].mobileCCC ? data[i].mobileCCC : 0;
          }else{
            let needed = fees - monthlyData[monthlyIndex].rmsMonthlySum;

            if(needed > 0){

              if((needed - data[i].usd) >  0){
              data[i].amountAllocatedJAG = data[i].usd;
              data[i].rmsRunningMonthlyPaydown = needed - data[i].usd;

              data[i].runningMonthlySK = calchipKit;
              data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
              data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;

              }else{

                data[i].amountAllocatedJAG = needed;
                data[i].rmsRunningMonthlyPaydown = '';

                data[i].remainingUSD = (data[i].usd  - needed) * 0.95;
                data[i].runningMonthlySK = calchipKit - data[i].remainingUSD;
                data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
                data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;


                calchipKit = calchipKit - data[i].remainingUSD;
              }

            }else{
                data[i].remainingUSD = data[i].usd * 0.95;
                data[i].runningMonthlySK = calchipKit - data[i].remainingUSD;
                data[i].mobileJAGDailyRMS = data[i].amountAllocatedJAG / data[i].coinGeckoPrice;
                data[i].mobileCCC = data[i].remainingUSD / data[i].coinGeckoPrice;


                calchipKit = calchipKit - data[i].remainingUSD;
            }
            
            monthlyData[monthlyIndex].rmsMonthlySum += data[i].amountAllocatedJAG ? parseFloat(data[i].amountAllocatedJAG) : 0;
            
            //month wise for tiles
            monthlyData[monthlyIndex].totalMonthlyMobileToJAGDailyRMS +=  data[i].mobileJAGDailyRMS ? data[i].mobileJAGDailyRMS : 0;
            monthlyData[monthlyIndex].totalMonthlyMobileToCCC +=  data[i].mobileCCC ? data[i].mobileCCC : 0;

          }

          totalMobileToJAGDailyRMS += data[i].mobileJAGDailyRMS ? data[i].mobileJAGDailyRMS : 0;
          totalMobileToCCC += data[i].mobileCCC ? data[i].mobileCCC : 0;

      }

      if(data.length > 0){
        return {
          totalMobileToJAGDailyRMS,
          totalMobileToCCC,
          monthlyData
        }
      }else{
        return {
          totalMobileToJAGDailyRMS,
          totalMobileToCCC, 
          monthlyData
        }
      }

    },
    async getHotspottyWalletHotspotsDetail(){
      let walletsArray = [];
      for(let i = 0; i < this.accountAdded.length; i++){
        walletsArray = [...walletsArray, this.accountAdded[i].address];
      }
      return new Promise (function( resolve ) {
        axios.post(`${HOTSPOTTY_BASE_URL}/wallets/hotspots`,{
          owner: walletsArray
        }, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          res = res.data;
          resolve(res);
        })
        .catch(() => {
          resolve({});
        })
      })
    },
    
    async getHotspottyHotspot(hotspotAddress){
      return new Promise (function( resolve ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          res = res.data.data;
          resolve(res);
        })
        .catch(() => {
          resolve({});
        })
      })
    },
    getHotspottyWalletHotspots(wallet){
      return new Promise (function( resolve, reject ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/wallets/${wallet}/hotspots`, {
          headers: {
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          resolve(res.data.data.hotspots);
        }).catch((err) => {
          resolve([]);
        })
      })
    },
    showSpinner(){
      this.$root.isLoading = true;
      setTimeout(()=>{
        this.$root.isLoading = false;
      },1500);
    },
  },
};


</script>
<style>
.capitalize {
    text-transform: capitalize;
}
.style-1 {
  background-color: #ff5252;
}
.style-2 {
  background-color: rgb(255, 255, 255);
}

.v-card__title {
  word-break:break-word !important;
}
</style>